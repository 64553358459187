import React from 'react'
import { Link } from 'gatsby'
import logo from '../images/logo.png'

const Well = () => (
    <div
        style={{
            width: '100%',
            textAlign: 'center'

        }}
    >
        <img src={logo}></img>
        <h3> "Move any shed anywhere" </h3>
    </div>
)

export default Well
